import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{style:({ 'margin-left': _vm.marginChange, 'margin-right': _vm.marginChange }),attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"5"}},[_c(VCard,{staticClass:"mt-12 mx-auto py-4 elevation-6"},[_c(VCard,{staticClass:"mx-auto my-n10 elevation-6",attrs:{"color":"blue-grey darken-4","max-width":"calc(100% - 32px)"}},[_c(VCardTitle,{staticClass:"font-weight-light white--text center"},[_vm._v(" "+_vm._s(_vm.$t(_vm.content.tabHeading))+" "),_c(VSpacer),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"fab":"","elevation":"4","dark":"","ripple":"","x-small":"","color":"primary"},on:{"click":_vm.clearAndPush}},on),[_c(VIcon,[_vm._v("$mdiLogin")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.content.loginBtn)))])])],1)],1),_c(VCardText,{staticClass:"pt-12 pb-0"},[_c('div',{staticClass:"subheading font-weight-light grey--text"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t(_vm.content.bodytextPasswordLink))}})]),_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.confirmPasswordReset()}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"type":"submit","disabled":!_vm.isValid,"block":"","rounded":"","color":"primary","elevation":"6"}},[_vm._v(_vm._s(_vm.$t(_vm.content.confirmPasswordReset))+" ")])],1)],1),_c('alertError',{staticClass:"mt-0"}),_c('alert-success',{staticClass:"mt-0"}),_c(VDivider,{staticClass:"my-2"}),_c(VIcon,{staticClass:"mr-2",attrs:{"small":"","color":"primary"}},[_vm._v(" $mdiInformation ")]),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t(_vm.content.footnote1))+" "),_c('router-link',{attrs:{"to":"/registration"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.content.footnote2))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }