<template>
  <v-row
    align="center"
    justify="center"
    :style="{ 'margin-left': marginChange, 'margin-right': marginChange }"
  >
    <v-col cols="12" sm="8" md="5">
      <v-card class="mt-12 mx-auto py-4 elevation-6">
        <v-card
          color="blue-grey darken-4"
          class="mx-auto my-n10 elevation-6"
          max-width="calc(100% - 32px)"
        >
          <v-card-title class="font-weight-light white--text center">
            {{ $t(content.tabHeading) }}
            <v-spacer />
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  elevation="4"
                  dark
                  ripple
                  x-small
                  color="primary"
                  v-on="on"
                  @click="clearAndPush"
                >
                  <v-icon>$mdiLogin</v-icon>
                </v-btn>
              </template>
              <span>{{ $t(content.loginBtn) }}</span>
            </v-tooltip>
          </v-card-title>
        </v-card>
        <v-card-text class="pt-12 pb-0">
          <div class="subheading font-weight-light grey--text">
            <p v-html="$t(content.bodytextPasswordLink)" />
          </div>
          <v-form v-model="isValid" @submit.prevent="confirmPasswordReset()">
            <v-card-actions>
              <v-spacer />
              <v-btn
                type="submit"
                :disabled="!isValid"
                block
                rounded
                color="primary"
                elevation="6"
                >{{ $t(content.confirmPasswordReset) }}
              </v-btn>
            </v-card-actions>
          </v-form>
          <alertError class="mt-0" />
          <alert-success class="mt-0" />
          <v-divider class="my-2"></v-divider>
          <v-icon class="mr-2" small color="primary">
            $mdiInformation
          </v-icon>
          <span class="text-caption">
            {{ $t(content.footnote1) }}
            <router-link to="/registration">
              {{ $t(content.footnote2) }}
            </router-link>
          </span>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable no-console */
import confResetPassword from "../conf/confResetPassword";
import AlertError from "../components/AlertError";
import AlertSuccess from "@/components/AlertSuccess";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ResetLink",
  components: { AlertSuccess, AlertError },
  data: () => ({
    content: { ...confResetPassword },
    isValid: true
  }),
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),

    marginChange() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "0px";
        default:
          return "-12px";
      }
    }
  },

  created() {
    this.checkStatus();
  },

  updated() {
    this.checkStatus();
  },

  watch: {
    isAuthenticated(val) {
      if (val === true) {
        this.$router.push({ name: "usercompany" }).catch(e => e);
      }
    },
    immediate: true
  },

  methods: {
    ...mapActions({
      confirmPasswordReset: "auth/confirmPasswordReset"
    }),
    ...mapMutations({
      global_clearError: "global_clearError",
      set_status: "auth/set_status"
    }),

    clearAndPush() {
      this.global_clearError();
      this.set_status(null);
      this.$router.push("/").catch(e => e);
    },
    checkStatus() {
      if (this.isAuthenticated && this.$route.name === "resetlink") {
        this.$router.push("usercompany").catch(e => e);
      }
    }
  }
};
</script>
